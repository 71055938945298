import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  Modal,
  Button,
  Form,
  Badge,
  Pill,
} from "react-bootstrap";
import { RecruiterProfileCard } from "Stories/ProfileCard.stories";
import API from "API";
import { Link } from "react-router-dom";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import styled, { keyframes } from "styled-components";
import { NavBarRecruiterWithItems } from "Stories/Navigation.stories.js";
import { Avatar } from "Stories/Avatars.stories.js";
import cityMap from "city-lat-long-map";
import * as turf from "@turf/turf";
import { geocoder } from "GeoSelect";

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const SliderWithTooltip = createSliderWithTooltip(Slider);

class SearchQueryResultsScreen extends Component {
  state = {
    recruiterSearchQueryResult: {
      filteredCandidates: [],
      candidates: [],
    },
    searchTitle: "",
    matches: [],
    recruiter: {},
  };

  componentDidMount() {
    let searchQueryId = this.props.match.params.searchQueryId;
    API.get(`/RecruiterSearchQueries/${searchQueryId}`).then((response) => {
      this.setState({
        ...response.data,
        recruiterSearchQueryResult: {
          ...response.data.recruiterSearchQueryResult,
          filteredCandidates: [],
        },
      });

      this.calculatePercentages();
    });
    API.get(`/UserMatches`).then((response) => {
      this.setState({
        matches: response.data.filter(
          (usermatch) =>
            usermatch.searchQueryId === this.props.match.params.searchQueryId
        ),
      });
    });
  }

  calculatePercentages() {
    let candidates = this.state.recruiterSearchQueryResult.candidates;
    let searchQuery = this.state;

    let percentageCandidates = candidates.map((c) => {
      let totalYearsWorkExperience =
        c.companyTypeEarly +
        c.companyTypeLate +
        c.companyTypeSmall +
        c.companyTypeMedium +
        c.companyTypeLarge;
      let levelOfEducationPriority = c.currentDegree
        ? c.currentDegree.priority
        : 100;
      let desiredJobPositions = c.userDesiredJobPositionSpecialties.map(
        (udjp) => udjp.jobPositionSpecialty.jobPositionId
      );
      let desiredJobPositionSpecialties =
        c.userDesiredJobPositionSpecialties.map(
          (udjp) => udjp.jobPositionSpecialtyId
        );
      let desiredIndustries = c.userDesiredIndustries.map((udi) => udi.id);
      let desiredJobLevel = c.userDesiredJobLevelId;
      let desiredSalary = c.desiredAnnualSalaryMin;
      let desiredCompanyTypes = c.userDesiredCompanyTypes.map(
        (udct) => udct.id
      );
      let desiredJobType = c.employmentTypeId;
      // location
      let remoteOk = c.remoteOk;
      if ("desiredCities" in c) {
        var desiredCities = c.desiredCities.map((dc) => dc.value);
      } else {
        var desiredCities = [];
      }

      let desiredRadius = c.desiredSearchDistanceMin;
      if (desiredRadius == 0) {
        desiredRadius = 1;
      }

      let points = 0;

      console.log("----------------------");

      if (
        totalYearsWorkExperience >= this.state.workExperienceMin &&
        totalYearsWorkExperience <= this.state.workExperienceMax
      ) {
        console.log("Work Experience Match");
        points = points + 9;
      }

      if (levelOfEducationPriority <= this.state.degree.priority) {
        console.log("Education match");
        points = points + 6;
      }

      if (desiredJobPositions.includes(this.state.jobPositionId)) {
        console.log("Job Position Match");
        points = points + 7;
      }

      if (
        desiredJobPositionSpecialties.includes(
          this.state.jobPositionSpecialtyId
        )
      ) {
        console.log("Position Specialty Match");
        points = points + 30;
      }

      if (desiredIndustries.includes(this.state.industryId)) {
        points = points + 7;
        console.log("Industry Match");
      }

      if (desiredJobLevel === this.state.jobLevelId) {
        points = points + 3;
        console.log("Job Level Match");
      }

      if (
        desiredSalary >= this.state.salaryMin &&
        desiredSalary <= this.state.salaryMax
      ) {
        console.log("Salary Match");
        points = points + 15;
      }

      if (desiredCompanyTypes.includes(this.state.companyTypeId)) {
        console.log("Company Types Match");
        points = points + 4;
      }

      if (desiredJobType === this.state.employmentTypeId) {
        points = points + 2;
        console.log("Job Type Match");
      }

      if (remoteOk) {
        points = points + 17;
      } else {
        // location intersection
        let desiredGeoCoordinates = desiredCities.map((dc) => {
          let coordinates = geocoder[dc];
          return coordinates;
        });

        desiredGeoCoordinates = desiredGeoCoordinates.filter((d) => d !== null);

        let searchQueryCoordinates = geocoder[this.state.cities[0].value];
        console.log("Search Query Coo", searchQueryCoordinates);
        console.log("Candidate Profile Coo", desiredGeoCoordinates);
        let turfPoint = [
          searchQueryCoordinates.longitude,
          searchQueryCoordinates.latitude,
        ];

        let searchQueryCircle = turf.circle(
          turfPoint,
          this.state.searchDistanceMin,
          { units: "miles" }
        );

        let candidateCircles = desiredGeoCoordinates.map((dgc) => {
          return turf.circle([dgc.longitude, dgc.latitude], desiredRadius, {
            units: "miles",
          });
        });

        let intersectionHappened = candidateCircles.map((candidateCircle) =>
          turf.intersect(searchQueryCircle, candidateCircle)
        );

        console.log("Candidate Intersection Results", intersectionHappened);

        if (
          intersectionHappened.filter((intersection) => intersection !== null)
            .length > 0
        ) {
          console.log("Location Match");
          points = points + 17;
        }
      }

      return {
        ...c,
        percentage: points,
      };
    });

    console.log(percentageCandidates);

    this.setState({
      recruiterSearchQueryResult: {
        filteredCandidates: percentageCandidates.sort(
          (a, b) => b.percentage - a.percentage
        ),
        candidates: percentageCandidates.sort(
          (a, b) => b.percentage - a.percentage
        ),
      },
    });
  }

  handleSendBulk = () => {
    let { candidates } = this.state.recruiterSearchQueryResult;
    let createCandidatePromises = candidates.map((candidate) => {
      return API.post(`/UserMatches`, {
        candidateId: candidate.id,
        recruiterId: this.props.userToken.userId,
        searchQueryId: this.props.match.params.searchQueryId,
        candidateEmail: candidate.email,
        hiringCompany: this.state.hiringCompany,
      });
    });

    Promise.all(createCandidatePromises).then((response) => {
      this.props.history.push("/recruiter/messages");
    });
  };

  render() {
    var _candidate$userDesire,
      _candidate,
      _candidate$userDesire2,
      _candidate$userDesire3;
    return (
      <Container noGutters={true}>
        <NavBarRecruiterWithItems {...this.props} />
        <Row>
          <Col md={3}>
            <RecruiterProfileCard {...this.props} />
          </Col>
          <Col>
            <SearchesContainer>
              <SearchesHeadingContainer>
                <SearchesHeadingMenuTitle style={{ fontWeight: "bold" }}>
                  {this.state.searchTitle} Search Query
                </SearchesHeadingMenuTitle>
                {/* <SearchesHeadingMenuItem>
                  Edit Search Query
                </SearchesHeadingMenuItem> */}
              </SearchesHeadingContainer>
              <SearchBarContainer>
                <SearchBarHeadingContainer>
                  <SearchBarHeadingTitle>Bulk Matches</SearchBarHeadingTitle>
                  <SearchBarHeadingEdit>
                    Candidate count:{" "}
                    {"candidates" in this.state.recruiterSearchQueryResult
                      ? this.state.recruiterSearchQueryResult.candidates.length
                      : 0}
                  </SearchBarHeadingEdit>
                </SearchBarHeadingContainer>
                <SearchBarPercentageContainer>
                  <p
                    style={{
                      fontWeight: "bold",
                      margin: 0,
                      marginLeft: 10,
                      marginRight: 10,
                    }}
                  >
                    0%
                  </p>
                  <RangeContainer>
                    <SliderWithTooltip
                      min={0}
                      max={100}
                      allowCross={false}
                      onChange={(v) =>
                        this.setState({
                          filterValue: v,
                          recruiterSearchQueryResult: {
                            ...this.state.recruiterSearchQueryResult,
                            filteredCandidates:
                              this.state.recruiterSearchQueryResult.candidates.filter(
                                (c) => c.percentage >= v
                              ),
                          },
                        })
                      }
                      tipProps={{ visible: true }}
                      tipFormatter={(value) => `${value}%`}
                      defaultValue={70}
                      reverse={false}
                      railStyle={{
                        backgroundColor: "#f5f5f5",
                        height: 10,
                      }}
                      trackStyle={[
                        { backgroundColor: "#fa9754", height: 10 },
                        { backgroundColor: "#fa9754", height: 10 },
                      ]}
                      handleStyle={[
                        {
                          backgroundColor: "#fa9754",
                          borderColor: "#fa9754",
                          width: 17,
                          height: 17,
                          top: 6,
                        },
                        {
                          backgroundColor: "#fa9754",
                          borderColor: "#fa9754",
                          width: 17,
                          height: 17,
                          top: 6,
                        },
                      ]}
                      dotStyle={{
                        display: "none",
                      }}
                    />
                  </RangeContainer>
                  <p
                    style={{
                      fontWeight: "bold",
                      margin: 0,
                      marginLeft: 10,
                      marginRight: 30,
                    }}
                  >
                    100%
                  </p>
                  <Button
                    variant="outline-primary"
                    onClick={this.handleSendBulk}
                    className=" "
                  >
                    Send Bulk
                  </Button>
                </SearchBarPercentageContainer>
                <SearchBarFilterContainer>
                  <SearchBarFilterItem>Distance</SearchBarFilterItem>
                  <SearchBarFilterItem>Match Percentage</SearchBarFilterItem>
                  <SearchBarFilterItem>Newest</SearchBarFilterItem>
                  <SearchBarFilterItem
                    style={{
                      color: "#fa9754",
                      fontWeight: "bold",
                    }}
                  >
                    All
                  </SearchBarFilterItem>
                </SearchBarFilterContainer>
              </SearchBarContainer>
              <SearchResultsContainer>
                {this.state.recruiterSearchQueryResult.filteredCandidates
                  .length === 0 && (
                  <p
                    style={{
                      fontSize: 20, fontWeight: "bold",
                      color: "#999",
                      textAlign: "center",
                      marginTop: 20,
                    }}
                  >
                    No candidates found. Please update your search query, or
                    refine your search to find candidates.
                  </p>
                )}
                {this.state.recruiterSearchQueryResult.filteredCandidates.map(
                  (candidate) => (
                    // <SearchItemContainer>
                    //   <Avatar
                    //     width={35}
                    //     height={35}
                    //     character={candidate.avatar.slug}
                    //   />
                    //   <SearchItemTitle>
                    //     GoGig User {candidate.anonymousUsername}
                    //   </SearchItemTitle>
                    //   <SearchItemDetailsContainer>
                    //     <SearchItemDetailContainer>
                    //       <SearchItemDetailTitle>
                    //         Match Percentage
                    //       </SearchItemDetailTitle>
                    //       <SearchItemDetailResult>100%</SearchItemDetailResult>
                    //     </SearchItemDetailContainer>
                    //     <SearchItemDetailContainer>
                    //       <SearchItemDetailTitle>
                    //         Position Type:
                    //       </SearchItemDetailTitle>
                    //       <SearchItemDetailResult>
                    //         {
                    //           candidate.userDesiredJobPositionSpecialties[0]
                    //             .jobPositionSpecialty.jobPosition.name
                    //         }
                    //       </SearchItemDetailResult>
                    //     </SearchItemDetailContainer>
                    //     <SearchItemDetailContainer>
                    //       <SearchItemDetailTitle>
                    //         Desired salary:
                    //       </SearchItemDetailTitle>
                    //       <SearchItemDetailResult>
                    //         ${candidate.desiredAnnualSalaryMin}
                    //       </SearchItemDetailResult>
                    //     </SearchItemDetailContainer>
                    //     <SearchItemDetailContainer>
                    //       <SearchItemDetailTitle>
                    //         Position Specialty:
                    //       </SearchItemDetailTitle>
                    //       <SearchItemDetailResult>
                    //         {
                    //           candidate.userDesiredJobPositionSpecialties[0]
                    //             .jobPositionSpecialty.name
                    //         }
                    //       </SearchItemDetailResult>
                    //     </SearchItemDetailContainer>
                    //     <SearchItemDetailContainer>
                    //       <SearchItemDetailTitle>
                    //         Desired location:
                    //       </SearchItemDetailTitle>
                    //       <SearchItemDetailResult>
                    //         {candidate.desiredCities
                    //           .map(city => city.value)
                    //           .join(", ")}
                    //       </SearchItemDetailResult>
                    //     </SearchItemDetailContainer>
                    //   </SearchItemDetailsContainer>
                    //   <Link
                    //     to={`/recruiter/candidate/${candidate.id}/${this.props.match.params.searchQueryId}`}
                    //   >
                    //     <Button size="sm" block>
                    //       View Profile
                    //     </Button>
                    //   </Link>
                    // </SearchItemContainer>
                    <Link
                      to={{
                        pathname: `/recruiter/candidate/${candidate.id}/${this.props.match.params.searchQueryId}`,
                        state: {
                          hiringCompany: this.state.hiringCompany,
                        },
                      }}
                      style={{
                        textDecoration: "none",
                        color: "#000",
                      }}
                    >
                      <SearchItemContainerTwo
                        style={{
                          marginTop: 20,
                        }}
                      >
                        {this.state.matches.find(
                          (el) => el.candidateId === candidate.id
                        ) && (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-end",
                            }}
                          >
                            <p
                              style={{
                                fontSize: 14,
                                color: "#999",
                                marginRight: 5,
                              }}
                            >
                              Invite Sent
                            </p>
                            <img
                              style={{
                                width: 20,
                                height: 20,
                              }}
                              src={require("images/icons/invite-sent-thumb-icon.png")}
                            />
                          </div>
                        )}
                        <div className="percentage-avatar-container">
                          <div className="percentage-container">
                            <p>{candidate.percentage}%</p>
                          </div>
                          {candidate.avatar ? (
                            <Avatar
                              className="ava"
                              width={40}
                              height={40}
                              character={candidate.avatar.slug}
                            />
                          ) : (
                            <Avatar
                              className="ava"
                              width={40}
                              height={40}
                              character={"bear"}
                            />
                          )}
                        </div>
                        <div className="anon-username">
                          <p>aha!hire User {candidate.anonymousUsername}</p>
                        </div>
                        <div className="detail-container">
                          <p className="detail-title">Position Type:</p>
                          <p className="detail-info">
                            {(_candidate$userDesire =
                              (_candidate = candidate) === null ||
                              _candidate === void 0
                                ? void 0
                                : (_candidate$userDesire2 =
                                    _candidate
                                      .userDesiredJobPositionSpecialties[0]) ===
                                    null || _candidate$userDesire2 === void 0
                                ? void 0
                                : (_candidate$userDesire3 =
                                    _candidate$userDesire2.jobPositionSpecialty) ===
                                    null || _candidate$userDesire3 === void 0
                                ? void 0
                                : _candidate$userDesire3.jobPosition.name) !==
                              null && _candidate$userDesire !== void 0
                              ? _candidate$userDesire
                              : "Any Position"}
                          </p>
                        </div>
                        <div className="detail-container">
                          <p className="detail-title">Position specialty:</p>
                          <p className="detail-info">
                            {(_candidate$userDesire =
                              (_candidate = candidate) === null ||
                              _candidate === void 0
                                ? void 0
                                : (_candidate$userDesire2 =
                                    _candidate
                                      .userDesiredJobPositionSpecialties[0]) ===
                                    null || _candidate$userDesire2 === void 0
                                ? void 0
                                : (_candidate$userDesire3 =
                                    _candidate$userDesire2.jobPositionSpecialty) ===
                                    null || _candidate$userDesire3 === void 0
                                ? void 0
                                : _candidate$userDesire3.name) !== null &&
                            _candidate$userDesire !== void 0
                              ? _candidate$userDesire
                              : "Any Specialty"}
                          </p>
                        </div>
                        {/* <div className="detail-container">
                          <p className="detail-title">Desired salary:</p>
                          <p className="detail-info">
                            ${candidate.desiredAnnualSalaryMin}
                          </p>
                        </div> */}
                        <div className="detail-container">
                          <p className="detail-title">Desired location:</p>
                          <p className="detail-info">
                            {"desiredCities" in candidate
                              ? candidate.desiredCities
                                  .map((city) => city.value)
                                  .join(", ")
                              : "None"}
                          </p>
                        </div>
                      </SearchItemContainerTwo>
                    </Link>
                  )
                )}
              </SearchResultsContainer>
            </SearchesContainer>
          </Col>
        </Row>
      </Container>
    );
  }
}

const SearchesContainer = styled.div`
  background: white;
  border: 1px solid #f5f5f5;
  flex: 1;
  margin-top: 20px;
  min-height: 400px;
  padding: 20px;
`;

const SearchesHeadingContainer = styled.div`
  flex: 1;
  display: flex;
  border-bottom: 1px solid #f5f5f5;
  justify-content: space-between;
`;

const SearchesHeadingMenuItem = styled.p`
  color: #fa9754;
  font-size: 14px;
`;

const SearchesHeadingMenuTitle = styled.p`
  margin-right: 20px;
  color: #000;
  font-size: 14px;
`;

const SearchBarContainer = styled.div``;
const SearchBarHeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
`;
const SearchBarHeadingTitle = styled.p`
  font-size: 14px;
  font-weight: bold;
`;
const SearchBarHeadingEdit = styled.p`
  color: #999;
  font-size: 14px;
`;
const SearchBarPercentageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;
const RangeContainer = styled.div`
  flex: 1;
`;
const SearchBarFilterContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-top: 20px;
`;
const SearchBarFilterItem = styled.div`
  font-size: 14px;
  color: #999;
  :hover {
    color: red;
    cursor: pointer;
  }
  margin-left: 20px;
`;

const SearchResultsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
`;
const SearchItemContainer = styled.div`
  padding: 10px;
  border: 1px solid #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
`;
const SearchItemImage = styled.img`
  width: 50px;
  height: 50px;
`;
const SearchItemTitle = styled.p`
  font-weight: bold;
  font-size: 14px;
  margin-top: 20px;
`;

const SearchItemDetailsContainer = styled.div``;

const SearchItemDetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const SearchItemDetailTitle = styled.p`
  font-size: 12px;
  margin-right: 30px;
`;
const SearchItemDetailResult = styled.p`
  font-size: 12px;
  color: #999;
`;

const SearchItemContainerTwo = styled.div`
  border: 2px solid #f5f5f5;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 350px;
  :hover {
    cursor: pointer;
    background: #f5f5f5;
  }
  .percentage-avatar-container {
    .ava {
      position: relative;
      bottom: 20px;
      left: 35px;
    }
    .percentage-container {
      background: #fa9754;
      border-radius: 50px;
      width: 60px;
      height: 60px;
      font-size: 14px;
      color: white;
      font-weight: bold;
      text-align: center;
      p {
        margin: 0;
        padding: 0;
        line-height: 65px;
      }
    }
  }
  .anon-username {
    p {
      font-weight: bold;
      font-size: 14px;
    }
  }
  .detail-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    .detail-title {
      font-size: 12px;
    }
    .detail-info {
      color: silver;
      font-size: 12px;
      text-align: right;
    }
  }
`;

export default SearchQueryResultsScreen;
