import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  Modal,
  Button,
  Form,
  Badge,
  Pill,
} from "react-bootstrap";
import { ProfileCard } from "Stories/ProfileCard.stories";
import API from "API";
import { Link } from "react-router-dom";
import { NavBarRecruiterWithItems } from "Stories/Navigation.stories.js";
import styled from "styled-components";
import PersonalitySnapshot from "PersonalitySnapshot/recruiter.js";
import { AvatarURL } from "Stories/Avatars.stories.js";

import { ReactComponent as SearchIcon } from "images/icons/Search.svg";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { ReactComponent as StatusRoad } from "../images/profileMedia/img-path.svg";
import { ReactComponent as LikeBtn } from "../images/icons/accept-match-button.svg";
import { ReactComponent as DislikeBtn } from "../images/icons/skip-match-button.svg";

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

class RecruiterViewCandidateScreen extends Component {
  state = {
    avatar: {
      slug: "",
    },
    anonymousUsername: "",
    displayModal: "",
    userDesiredIndustry: "",
    userDesiredCompanyType: "",
    userDesiredJobPositionSpecialties: [],
    userDesiredJobLevel: "",
    desiredAnnualSalaryMin: 0,
    desiredAnnualSalaryMax: 0,
    desiredSearchDistanceMin: 0,
    desiredSearchDistanceMax: 0,
    desiredStates: [],
    desiredCities: [],
    currentCity: {},
    currentState: {},
    remoteOk: false,
    jobSearchStage: {},
    currentJobPosition: {},
    currentJobPositionSpecialty: {},
    currentJobLevel: {},
    currentCompanyType: {},
    userDesiredIndustries: [],
    userDesiredCompanyTypes: [],
    currentDegree: {},
    currentIndustry: {},
    currentAnnualSalaryMin: 0,
    currentAnnualSalaryMax: 0,
    jobSearchStages: [],
    updates: 0,
    companyTypeEarly: 0,
    companyTypeLate: 0,
    companyTypeSmall: 0,
    companyTypeMedium: 0,
    companyTypeLarge: 0,
    employmentTypes: [],
    employmentType: {},
    onboard: false,
    writingSampleModal: true,
    writingSampleText: "",
    writingSampleTypes: [],
    writingSampleTypeId: "",
    matches: [],
    employmentEligibility: {},
    currentRegion: {}
  };

  componentDidMount() {
    API.get(`/UserMatches`).then((response) => {
      this.setState({
        matches: response.data.filter(
          (usermatch) =>
            usermatch.searchQueryId === this.props.match.params.searchQueryId
        ),
      });
    });
    API.get(
      `/UserProfiles/${this.props.match.params.candidateId}?filter={
        "include": ["avatar", "userDesiredIndustry", "userDesiredCompanyType", "userDesiredJobLevel", "jobSearchStage", "currentJobPosition", "currentJobLevel", "currentCompanyType", "currentDegree", "currentIndustry", "currentJobPositionSpecialty", "userDesiredIndustries", "userDesiredCompanyTypes", "employmentType", "userWritingSample"]
      }`
    ).then((response) => {
      this.setState(response.data);
      if ("userWritingSample" in response.data) {
        this.setState({
          writingSample: response.data.userWritingSample,
          writingSampleText: response.data.userWritingSample.writingSample,
        });
      }
    });
    API.post(`/UserProfileViews`, {
      candidateId: this.props.match.params.candidateId,
      recruiterId: this.props.userToken.userId,
    });
    API.get(`/JobSearchStages`).then((response) => {
      this.setState({
        jobSearchStages: response.data,
      });
    });
  }

  handleMatch = () => {
    API.post(`/UserMatches`, {
      candidateId: this.props.match.params.candidateId,
      recruiterId: this.props.userToken.userId,
      searchQueryId: this.props.match.params.searchQueryId,
      candidateEmail: this.state.email,
      hiringCompany: this.props.location.state.hiringCompany,
    });
    this.props.history.push(
      "/recruiter/searches/" + this.props.match.params.searchQueryId
    );
  };

  handleSkip = () => {
    this.props.history.push(
      "/recruiter/searches/" + this.props.match.params.searchQueryId
    );
  };

  render() {
    let avatarURL = require("../" + AvatarURL(this.state.avatar.slug));
    return (
      <Container
        style={{
          maxWidth: 1325,
        }}
      >
        <NavBarRecruiterWithItems {...this.props} />
        <Row className="mt-3">
          <Col>
            <ProfileHeadingContainer
              style={{
                borderRadius: 8,
                backgroundColor: "#fa9754",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              {/* <EditBar
                style={{
                  paddingTop: 40,
                }}
              >
                <StyledEditIcon
                  src={require("../images/icons/edit/edit.svg")}
                  onClick={() => this.editModal("pitch")}
                />
              </EditBar> */}
              <ProfileHeadingIcon src={avatarURL} />
              <ProfileHeadingTitle>
                aha!hire User {this.state.anonymousUsername}
              </ProfileHeadingTitle>
              <SearchIcon
                style={{
                  marginBottom: 10,
                }}
              />
              <ProfileHeadingPitch>
                {this.state.userPitch || ""}
              </ProfileHeadingPitch>
            </ProfileHeadingContainer>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <InfoCardContainer>
              <InfoCardIcon src={require("images/icons/Tip.svg")} />
              <InfoCardMessage style={{ fontSize: 16 }}>
                <strong>Work Experience</strong>
              </InfoCardMessage>
              {this.state.companyTypeEarly +
                this.state.companyTypeLate +
                this.state.companyTypeSmall +
                this.state.companyTypeMedium +
                this.state.companyTypeLarge ===
                0 && (
                <InfoCardMessage className="text-center">
                  This candidate has no years of experience, and is likely
                  searching for their first job
                </InfoCardMessage>
              )}
              {this.state.companyTypeEarly > 0 && (
                <InfoCardMessage>
                  <strong style={{ color: "#2b4e5d", fontWeight: "normal" }}>
                    Early Stage Startup
                  </strong>
                  <br />
                  Candidate has worn multiple hats to help a company get off the
                  ground
                </InfoCardMessage>
              )}
              {this.state.companyTypeLate > 0 && (
                <InfoCardMessage>
                  <strong style={{ color: "#2b4e5d", fontWeight: "normal" }}>
                    Late Stage Startup
                  </strong>
                  <br />
                  Candidate has refined the processes and/or product to help
                  company prepare to scale
                </InfoCardMessage>
              )}
              {this.state.companyTypeSmall > 0 && (
                <InfoCardMessage>
                  <strong style={{ color: "#2b4e5d", fontWeight: "normal" }}>
                    Small (0-50 Employees)
                  </strong>
                  <br />
                  Candidate has supported an established enterprise whose focus
                  was on retention and opportunity
                </InfoCardMessage>
              )}
              {this.state.companyTypeMedium > 0 && (
                <InfoCardMessage>
                  <strong style={{ color: "#2b4e5d", fontWeight: "normal" }}>
                    Medium Established (51-200 Employees)
                  </strong>
                  <br />
                  Candidate has supported a mid-size enterprise whose focus was
                  to grow or maintain market share
                </InfoCardMessage>
              )}
              {this.state.companyTypeLarge > 0 && (
                <InfoCardMessage>
                  <strong style={{ color: "#2b4e5d", fontWeight: "normal" }}>
                    Large (201+ Employees)
                  </strong>
                  <br />
                  Candidate has supported a large enterprise whose focus was to
                  turn new business models into revenue generateing opportunties
                </InfoCardMessage>
              )}
            </InfoCardContainer>
          </Col>
          <Col md={6}>
            <ProfileContainer>
              <ProfileContainerTitle>
                <img src={require("images/icons/Career.svg")} />
                <p>Career Profile</p>
                {this.state.matches.find(
                  (el) => el.candidateId === this.state.id
                ) && (
                  <img
                    style={{}}
                    src={require("images/icons/view-invite-sent-badge.png")}
                  />
                )}
              </ProfileContainerTitle>

              <div className="d-md-none d-lg-block d-sm-none d-md-block d-none d-sm-block">
                <p className="pl-2">
                  <b>Current Career Status</b>
                </p>

                <StatusBubblesContainer>
                  {this.state.jobSearchStages.map((jobSearchStage) => (
                    <div>
                      <StatusBubble
                        selected={
                          jobSearchStage.id === this.state.jobSearchStage.id
                        }
                      >
                        <StatusBubbleText>
                          {jobSearchStage.name.toUpperCase()}
                        </StatusBubbleText>
                      </StatusBubble>
                      <StatusBubblePointy
                        selected={
                          jobSearchStage.id === this.state.jobSearchStage.id
                        }
                      />
                    </div>
                  ))}
                </StatusBubblesContainer>
                <StatusRoad />
              </div>
              <ProfileSummaryContainer>
                <Row>
                  <Col>
                    <ProfileSummarySectionContainer>
                      <ProfileSummarySectionItemsContainer>
                        <div
                          style={{
                            backgroundImage:
                              "url(/profileMedia/img-mountain.svg)",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            backgroundPosition: "bottom",
                          }}
                        >
                          <ProfileSummarySectionItem
                            style={{
                              height: 100,
                            }}
                          >
                            <ProfileSummarySectionItemTitle>
                              Current Position Type
                            </ProfileSummarySectionItemTitle>
                            <ProfileSummarySectionItemData>
                              {this.state.currentJobPosition.name} with a
                              specialty in{" "}
                              {this.state.currentJobPositionSpecialty.name}
                            </ProfileSummarySectionItemData>
                          </ProfileSummarySectionItem>
                          <ProfileSummarySectionItem>
                            <ProfileSummarySectionItemTitle>
                              Current Industry
                            </ProfileSummarySectionItemTitle>
                            <ProfileSummarySectionItemData>
                              {this.state.currentIndustry.name}
                            </ProfileSummarySectionItemData>
                          </ProfileSummarySectionItem>
                          <ProfileSummarySectionItem>
                            <ProfileSummarySectionItemTitle>
                              Current Job Level
                            </ProfileSummarySectionItemTitle>
                            <ProfileSummarySectionItemData>
                              {this.state.currentJobLevel.name}
                            </ProfileSummarySectionItemData>
                          </ProfileSummarySectionItem>
                        </div>

                        <div
                          style={{
                            backgroundImage:
                              "url(/profileMedia/img-skyline.svg)",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            backgroundPosition: "bottom",
                          }}
                        >
                          {/* <ProfileSummarySectionItem>
                            <ProfileSummarySectionItemTitle>
                              Most Recent Annual Salary
                            </ProfileSummarySectionItemTitle>
                            <ProfileSummarySectionItemData>
                              $
                              {this.state.currentAnnualSalaryMin.toLocaleString()}
                            </ProfileSummarySectionItemData>
                          </ProfileSummarySectionItem> */}
                          <ProfileSummarySectionItem style={{ height: 175 }}>
                            <ProfileSummarySectionItemTitle>
                              Total Years of Career Experience, Based on Company
                              Type
                            </ProfileSummarySectionItemTitle>
                            {this.state.companyTypeEarly > 0 && (
                              <ProfileSummarySectionItemData>
                                {this.state.companyTypeEarly}{" "}
                                {this.state.companyTypeEarly > 1 ? "yrs" : "yr"}{" "}
                                in Early Stage Startup
                              </ProfileSummarySectionItemData>
                            )}
                            {this.state.companyTypeLate > 0 && (
                              <ProfileSummarySectionItemData>
                                {this.state.companyTypeLate}{" "}
                                {this.state.companyTypeLate > 1 ? "yrs" : "yr"}{" "}
                                in Late Stage Startup
                              </ProfileSummarySectionItemData>
                            )}
                            {this.state.companyTypeSmall > 0 && (
                              <ProfileSummarySectionItemData>
                                {this.state.companyTypeSmall}{" "}
                                {this.state.companyTypeSmall > 1 ? "yrs" : "yr"}{" "}
                                in Small Established
                              </ProfileSummarySectionItemData>
                            )}
                            {this.state.companyTypeMedium > 0 && (
                              <ProfileSummarySectionItemData>
                                {this.state.companyTypeMedium}{" "}
                                {this.state.companyTypeMedium > 1
                                  ? "yrs"
                                  : "yr"}{" "}
                                in Medium Established
                              </ProfileSummarySectionItemData>
                            )}
                            {this.state.companyTypeLarge > 0 && (
                              <ProfileSummarySectionItemData>
                                {this.state.companyTypeLarge}{" "}
                                {this.state.companyTypeLarge > 1 ? "yrs" : "yr"}{" "}
                                in Large Established
                              </ProfileSummarySectionItemData>
                            )}
                          </ProfileSummarySectionItem>
                        </div>

                        <div
                          style={{
                            backgroundImage:
                              "url(/profileMedia/img-location.svg)",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "bottom",
                          }}
                        >
                          <ProfileSummarySectionItem style={{ height: 75 }}>
                            <ProfileSummarySectionItemTitle>
                              Current Home Location
                            </ProfileSummarySectionItemTitle>
                            <ProfileSummarySectionItemData>
                              {this.state.currentRegion.value}
                            </ProfileSummarySectionItemData>
                          </ProfileSummarySectionItem>



                 

                          <ProfileSummarySectionItem
                            style={{
                              marginTop: 30,
                              marginBottom: 30,
                            }}
                          >
                            <ProfileSummarySectionItemTitle>
                              Employment Status
                            </ProfileSummarySectionItemTitle>
                            <ProfileSummarySectionItemData>
                              {this.state.employmentEligibility.title}
                            </ProfileSummarySectionItemData>
                          </ProfileSummarySectionItem>
                        </div>

                        <div
                          className="mr-3"
                          style={{
                            backgroundImage:
                              "url(/profileMedia/img-diploma.svg)",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "bottom right",
                            backgroundSize: "100px 50px",
                          }}
                        >
                          <ProfileSummarySectionItem
                            style={{
                              marginTop: 30,
                              marginBottom: 30,
                            }}
                          >
                            <ProfileSummarySectionItemTitle>
                              Highest Level of Education Obtained
                            </ProfileSummarySectionItemTitle>
                            <ProfileSummarySectionItemData>
                              {this.state.currentDegree.name}
                            </ProfileSummarySectionItemData>
                          </ProfileSummarySectionItem>
                        </div>

                        {/* <ProfileSummarySectionItem>
                      <ProfileSummarySectionItemTitle>
                        Current Job Search Stage
                      </ProfileSummarySectionItemTitle>
                      <ProfileSummarySectionItemData>
                        {this.state.jobSearchStage.name}
                      </ProfileSummarySectionItemData>
                    </ProfileSummarySectionItem> */}
                      </ProfileSummarySectionItemsContainer>
                    </ProfileSummarySectionContainer>
                  </Col>
                  <Col>
                    <ProfileSummarySectionContainer>
                      {/* <ProfileSummarySectionHeading>
                    Where I Want To Be
                  </ProfileSummarySectionHeading>
                  <ProfileSummarySectionDescription>
                    Please describe your dream or ideal work. This could be
                    anything from your 1-year goals to 10-year goals. With this
                    information, we can help you find opportunties that you
                    never knew you could qualify for.
                  </ProfileSummarySectionDescription> */}
                      <ProfileSummarySectionItemsContainer>
                        <div
                          style={{
                            backgroundImage:
                              "url(/profileMedia/img-climber.svg)",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "bottom",
                          }}
                        >
                          <ProfileSummarySectionItem
                            style={{
                              height: 100,
                            }}
                          >
                            <ProfileSummarySectionItemTitle>
                              Desired Position Type(s)
                            </ProfileSummarySectionItemTitle>
                            {this.state.userDesiredJobPositionSpecialties.map(
                              (jobPositionSpecialty) => (
                                <ProfileSummarySectionItemData>
                                  {jobPositionSpecialty.jobPositionSpecialty
                                    .jobPosition.name +
                                    ": " +
                                    jobPositionSpecialty.jobPositionSpecialty
                                      .name +
                                    " (" +
                                    jobPositionSpecialty.experience +
                                    " yrs)"}
                                </ProfileSummarySectionItemData>
                              )
                            )}
                          </ProfileSummarySectionItem>
                          <ProfileSummarySectionItem>
                            <ProfileSummarySectionItemTitle>
                              Desired Industry
                            </ProfileSummarySectionItemTitle>
                            {this.state.userDesiredIndustries.map((i) => (
                              <ProfileSummarySectionItemData>
                                {i.name}
                              </ProfileSummarySectionItemData>
                            ))}
                          </ProfileSummarySectionItem>

                          <ProfileSummarySectionItem>
                            <ProfileSummarySectionItemTitle>
                              Desired Job Level
                            </ProfileSummarySectionItemTitle>
                            <ProfileSummarySectionItemData>
                              {this.state.userDesiredJobLevel.name}
                            </ProfileSummarySectionItemData>
                          </ProfileSummarySectionItem>
                        </div>

                        <div
                          style={{
                            backgroundImage: "url(/profileMedia/img-town.svg)",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "175px",
                            backgroundPosition: "right bottom",
                          }}
                        >
                          {/* <ProfileSummarySectionItem>
                            <ProfileSummarySectionItemTitle>
                              Desired Annual Salary
                            </ProfileSummarySectionItemTitle>
                            <ProfileSummarySectionItemData>
                              $
                              {this.state.desiredAnnualSalaryMin.toLocaleString()}
                            </ProfileSummarySectionItemData>
                          </ProfileSummarySectionItem> */}

                          <ProfileSummarySectionItem>
                            <ProfileSummarySectionItemTitle>
                              Desired Job Type
                            </ProfileSummarySectionItemTitle>
                            <ProfileSummarySectionItemData>
                              {this.state.employmentType.name}
                            </ProfileSummarySectionItemData>
                          </ProfileSummarySectionItem>

                          <ProfileSummarySectionItem
                            style={{
                              height: 175,
                            }}
                          >
                            <ProfileSummarySectionItemTitle>
                              Desired Company Type
                            </ProfileSummarySectionItemTitle>
                            {this.state.userDesiredCompanyTypes.map((i) => (
                              <ProfileSummarySectionItemData>
                                {i.name.replace(/ *\([^)]*\) */g, "")}
                              </ProfileSummarySectionItemData>
                            ))}
                          </ProfileSummarySectionItem>
                        </div>

                        <div
                          style={{
                            backgroundImage:
                              "url(/profileMedia/img-locationmap.svg)",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            backgroundPosition: "right",
                            backgroundSize: "200px",
                          }}
                        >
                          <ProfileSummarySectionItem>
                            <ProfileSummarySectionItemTitle>
                              Desired Location(s)
                            </ProfileSummarySectionItemTitle>
                            <ProfileSummarySectionItemData>
                              {this.state.desiredCities.map((cities) => (
                                <p className="p-0 m-0">{cities.value}</p>
                              ))}
                            </ProfileSummarySectionItemData>
                          </ProfileSummarySectionItem>

                          <ProfileSummarySectionItem
                            style={{
                              marginTop: 90,
                            }}
                          >
                            <ProfileSummarySectionItemTitle
                              style={{
                                fontWeight: "normal",
                                fontSize: 10,
                                maxWidth: 175,
                              }}
                            >
                              Candidate is interested in any position within{" "}
                              {this.state.desiredSearchDistanceMin} Miles around
                              these cities
                            </ProfileSummarySectionItemTitle>
                          </ProfileSummarySectionItem>
                        </div>

                        {/* <ProfileSummarySectionItem>
                          <ProfileSummarySectionItemTitle>
                            Willing To Work Remotely
                          </ProfileSummarySectionItemTitle>
                          <ProfileSummarySectionItemData>
                            {this.state.remoteOk ? "Yes" : "No"}
                          </ProfileSummarySectionItemData>
                        </ProfileSummarySectionItem> */}
                      </ProfileSummarySectionItemsContainer>
                    </ProfileSummarySectionContainer>
                  </Col>

                  <div
                    style={{
                      backgroundColor: "#fff",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {!this.state.matches.find(
                      (el) => el.candidateId === this.state.id
                    ) && (
                      <div>
                        <DislikeBtn
                          onClick={this.handleSkip}
                          style={{
                            height: 50,
                            width: 50,
                            margin: 20,
                            fill: "red",
                            cursor: "pointer",
                          }}
                        />
                        <LikeBtn
                          onClick={this.handleMatch}
                          style={{
                            margin: 20,
                            height: 50,
                            width: 50,
                            fill: "green",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    )}
                  </div>
                </Row>
              </ProfileSummaryContainer>
            </ProfileContainer>
          </Col>
          <Col md={3}>
            <PersonalitySnapshot
              {...this.props}
              updates={this.state.updates}
              editModal={this.editModal}
              updateOnboard={this.updateOnboard}
              writingSampleModal={true}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default RecruiterViewCandidateScreen;

const InfoCardContainer = styled.div`
  background: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;

const StyledEditIcon = styled.img`
  width: 15px;
  height: 15px;
  cursor: pointer;
  position: relative;
  top: 20px;
  right: 10px;
  margin: 0;
  padding: 0;
`;
const InfoCardIcon = styled.img`
  margin-bottom: 20px;
`;
const InfoCardMessage = styled.p`
  font-size: 12px;
  color: #999;
`;

const ProfileContainer = styled.div`
  margin-top: 20px;
  background: white;
`;
const ProfileHeadingContainer = styled.div`
  display: flex;
  background: white;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  padding-top: 60px;
`;
const ProfileHeadingIcon = styled.img``;
const ProfileHeadingTitle = styled.p`
  font-weight: 500;
  font-size: 14px;
  margin-top: 20px;
  color: white;
`;
const ProfileHeadingPitch = styled.p`
  text-align: center;
  color: #fff;
  font-size: 14px;
  word-wrap: break-word;
  max-width: 800px;
`;
const ProfileSummaryContainer = styled.div`
  background: white;
  padding: 20px;
  margin-top: 20px;
  display: flex;
`;

const ProfileSummarySectionContainer = styled.div`
  flex: 0.5;
  // background-repeat: no-repeat;
  // background-position: bottom right;
  // background-size: 500px;
  margin-top: 20px;
  // margin-bottom: 20px;
  // padding-bottom: 20px;
  border: 1px solid #d5d5d5;
  margin: 5px;
  height: 100%;
`;

const ProfileSummarySectionHeading = styled.p`
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  color: #fa9754;
`;
const ProfileSummarySectionDescription = styled.p`
  font-size: 12px;
  color: #999;
  margin: 0;
`;

const ProfileSummarySectionItemsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;

const ProfileSummarySectionItem = styled.div`
  margin-top: 0px;
  border-radius: 4px;
  padding: 10px;
  height: 65px;
`;
const ProfileSummarySectionItemTitle = styled.p`
  margin: 0;
  font-size: 13px;
  font-weight: bold;
`;
const ProfileSummarySectionItemData = styled.p`
  margin: 0;
  color: #000;
  font-size: 11px;
`;

const EditBar = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  height: 0;
`;
const EditTitle = styled.p`
  margin-right: 10px;
  cursor: pointer;
  margin: 0;
  margin-right: 10px;
  color: #999;
  :hover {
    color: red;
  }
`;

const SliderContainer = styled.div`
  width: 97%;
  padding: 20px;
`;

const StatusBubblesContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
`;

const StatusBubble = styled.div`
  width: 85px;
  height: 40px;
  background: ${(props) => (props.selected ? "#fa9754" : "#C3CFD5")};
  margin: 10px;
  margin-bottom: 0;
  border-radius: 4px;
  padding: 5px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const EditPitchContainer = styled.div`
  color: white;
  font-size: 13px;
  text-decoration: underline;
  cursor: pointer;
  :hover {
    color: #999;
  }
`;

const StatusBubblePointy = styled.div`
  width: 0px;
  height: 0px;
  position: relative;
  left: 48px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid ${(props) => (props.selected ? "#fa9754" : "#C3CFD5")};
`;

const StatusBubbleText = styled.p`
  font-size: 8px;
  text-align: center;
  margin: 0;
  padding: 0;
`;

const ProfileContainerTitle = styled.div`
  p {
    font-size: 18px;
    font-weight: 500;
    padding: 0;
    margin: 0;
    margin-left: 10px;
    flex-grow: 1;
  }
  padding-top: 20px;
  margin: 20px;
  border-bottom: 1px solid #f5f5f5;
  display: flex;
  align-items: center;
  padding-bottom: 20px;
`;

const ModalContainerTwo = styled.div`
  display: flex;
  flex-direction: row;
`;

const ModalCol = styled.div`
  background-color: #edeff0;
`;
