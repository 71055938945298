import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import classes from './index.module.css';
function TemplateFooter(props) {
  return (
    <footer
      className={classes.footerContainer}
    >
      <Grid
        container
        className={classes.footerGrid}
      >
        <Grid
          item
          xs={0}
          lg={2}
        />
        <Grid
          className={classes.footerSectionContainer}
          item
          xs={12}
          lg={3}
        />
        <Grid
          className={classes.footerSectionContainer}
          item
          xs={12}
          lg={2}
        />
        <Grid
          className={classes.footerSectionContainer}
          item
          xs={12}
          lg={3}
        >
        <a href="https://www.gogig.com" target="_blank" style={{display: 'flex', textDecoration: 'none'}}>
            <Typography
              className={classes.footerGogigTypography}
            >
              Powered by
            </Typography>
            <img
              className={classes.footerGogigLogo}
              src="/icons/gogig-logo.svg"
              alt=""
            />
          </a>
        </Grid>
        <Grid
          item
          xs={0}
          lg={2}
        />
      </Grid>
    </footer>
  );
}

TemplateFooter.propTypes = {};

export default TemplateFooter;