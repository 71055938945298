import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import LoginIcon from '@material-ui/icons/Input';

import classes from './index.module.css';

function NavMenuTemplate(props) {
  const { signInLink, desktopLogo, mobileLogo } = props;
  const [navbarOpacity, changeNavbarOpacity] = useState('rgba(255,255,255,0)');
  const [navSocials, changeNavSocials] = useState('block');
  const [navSignIn, changeNavSignIn] = useState('none');
  const [navThreshold, changeNavThreshold] = useState(false);
  const pathname = window.location.pathname.replace('/', '');

  // const onPageRender = () => {
  //   const initialPageValue = 0 + 100;
  //   const onScroll = () => {
  //     const originalScrollPosition = window.scrollY;
  //     const scrollPosition = window.scrollY + window.innerHeight / 8;
  //     // Initial Fade In
  //     if (initialPageValue > originalScrollPosition) {
  //       const scrollPercent = ((originalScrollPosition - 0) * 100) / (initialPageValue - 0);
  //       const navbarFadeIn = (1 * scrollPercent) / 100;
  //       changeNavbarOpacity(`rgba(255,255,255,${navbarFadeIn})`);
  //       changeNavSocials('block');
  //       changeNavSignIn('none');
  //       changeNavThreshold(false);
  //     }
  //     // Navbar Shows completely
  //     if (initialPageValue < originalScrollPosition) {
  //       changeNavbarOpacity('rgba(255,255,255,1)');
  //       changeNavSocials('none');
  //       changeNavSignIn('block');
  //       changeNavThreshold(true);
  //     }
  //   };
  //   window.addEventListener('scroll', onScroll);
  //   return () => window.removeEventListener('scroll', onScroll);
  // };

  // useEffect(() => {
  //   onPageRender();
  // }, []);

  return (
    <nav
      className={classes.navContainer}
      style={{
        background: navbarOpacity,
      }}
    >
      <Grid
        className={classes.navFullHeight}
        container
      >
        <Grid
          item
          xs={0}
          md={2}
        />
        <Grid
          className={classes.navFullHeight}
          item
          container
          xs={12}
          md={3}
          justify="center"
        >
          <Box
            className={clsx(classes.navLinkLogo, classes.logoDesktop)}
          >
            <Link
              to="/"
            >
              <img
                className={clsx(classes.logo, classes.desktopPadding)}
                src={desktopLogo}
                alt=""
              />
            </Link>
          </Box>
          <Box
            className={clsx(classes.navLinkLogo, classes.logoMobile)}
          >
            <Link
              to="/"
            >
              <img
                className={classes.logo}
                src={mobileLogo}
                alt=""
              />
            </Link>
          </Box>
        </Grid>
        <Grid
          item
          xs={0}
          md={2}
        />
        <Grid
          className={classes.socialsContainer}
          item
          xs={0}
          md={3}
        >
          <a
            href="https://www.instagram.com/aharecruitingexperts/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className={classes.navLogoIcon}
              src="/icons/instagram-white-icon.svg"
              alt="Instagram Small Logo"
            />
          </a>
          <a
            href="https://www.facebook.com/AHARecruitingExperts"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className={classes.navLogoIcon}
              src="/icons/facebook-white-icon.svg"
              alt="Facebook Small Logo"
            />
          </a>
          <a
            href="https://twitter.com/AHA_Recruiting"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className={classes.navLogoIcon}
              src="/icons/twitter-white-icon.svg"
              alt="Twitter Small Logo"
            />
          </a>
          <a
            href="https://www.linkedin.com/company/aha-recruiting-experts/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className={classes.navLogoIcon}
              src="/icons/linkedin-white-icon.svg"
              alt="LinkedIn Small Logo"
            />
          </a>
        </Grid>
        <Grid
          item
          xs={0}
          md={2}
        />
      </Grid>
    </nav>
  );
}

NavMenuTemplate.propTypes = {};

export default NavMenuTemplate;
