import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import LockIcon from '@material-ui/icons/Lock';
import MenuBookIcon from '@material-ui/icons/MenuBook';


import classes from './index.module.css';

function TemplateFooter(props) {
  return (
    <footer
      className={classes.footerContainer}
    >
      <Grid
        container
        className={classes.footerGrid}
      >
        <Grid
          item
          xs={0}
          lg={2}
        />
        <Grid
          className={classes.footerSectionContainer}
          item
          xs={12}
          lg={3}
        >
          <Typography
            className={classes.footerText}
          >
            Connect with us
          </Typography>
          <a
            href="https://www.instagram.com/aharecruitingexperts/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className={classes.footerIcon}
              src="/icons/instagram-blue-icon.svg"
              alt="Instagram Small Logo"
            />
          </a>
          <a
            href="https://www.facebook.com/AHARecruitingExperts"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className={classes.footerIcon}
              src="/icons/facebook-blue-icon.svg"
              alt="Facebook Small Logo"
            />
          </a>
          <a
            href="https://twitter.com/AHA_Recruiting"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className={classes.footerIcon}
              src="/icons/twitter-blue-icon.svg"
              alt="Twitter Small Logo"
            />
          </a>
          <a
            href="https://www.linkedin.com/company/aha-recruiting-experts/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className={classes.footerIcon}
              src="/icons/linkedin-blue-icon.svg"
              alt="LinkedIn Small Logo"
            />
          </a>
          <a
            href='/'
            target="_blank"
            rel="noreferrer"
          >
            <img
              className={classes.footerIcon}
              src="/icons/small-house-blue-icon.svg"
              alt="Home Logo"
            />
          </a>
        </Grid>
        <Grid
          className={classes.footerSectionContainer}
          item
          xs={12}
          lg={2}
        />
        <Grid
          className={classes.footerSectionContainer}
          item
          xs={12}
          lg={3}
        >
          <img
            className={classes.footerGogigLogo}
            src="/icons/poweredby-gogig-logo-blue.svg"
            alt=""
          />
        </Grid>
        <Grid
          item
          xs={0}
          lg={2}
        />
      </Grid>
    </footer>
  );
}

TemplateFooter.propTypes = {};

export default TemplateFooter;
