import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  Modal,
  Button,
  Form,
  Badge,
  Pill,
} from "react-bootstrap";
import { ProfileCard } from "Stories/ProfileCard.stories";
import API from "API";
import { Link } from "react-router-dom";
import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import styled from "styled-components";
import { NavBarWithItems } from "Stories/Navigation.stories.js";
import { ReactComponent as NotificationIcon } from "images/icons/Notifications.svg";

class NotificationSettingsScreen extends Component {
  state = {
    id: null,
    desktopProfileUpdates: false,
    desktopNewMatches: false,
    desktopJobs: false,
    desktopNews: false,
    emailProfileUpdates: false,
    emailNewMatches: false,
    emailJobs: false,
    emailNews: false,
    pushProfileUpdates: false,
    pushNewMatches: false,
    pushJobs: false,
    pushNews: false,
    unsubAll: false,
  };

  componentDidMount() {
    API.get(
      `/Notifications?filter[where][userProfileId]=${this.props.userToken.userId}`
    )
      .then((resp) => {
        let { data } = resp;
        console.log(data);
        if (data.length === 0) {
          API.post(`/Notifications`, {
            userProfileId: this.props.userToken.userId,
          }).then((resp) => this.setState(resp.data));
        } else {
          this.setState(data[0]);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  handleSubmit = () => {
    API.put(`/Notifications/${this.state.id}`, {
      ...this.state,
      userProfileId: this.props.userToken.userId,
    })
      .then((resp) => {
        this.setState(resp.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  handleChange = (e) => {
    let { name, value, checked } = e.target;
    this.setState({
      [name]: checked,
    });
  };

  render() {
    return (
      <Container>
        <NavBarWithItems {...this.props} />
        <Row>
          <Col md={3}>
            <ProfileCard {...this.props} />
          </Col>
          <Col>
            <SettingsContainer>
              <SettingsHeading>
                <div className="mr-2">
                  <NotificationIcon
                    style={{
                      width: 20,
                      height: 20,
                      position: "relative",
                      bottom: 2,
                    }}
                  />
                </div>
                <SettingsTitle>Notifications</SettingsTitle>
              </SettingsHeading>
              <Form>
                <Form.Row>
                  <Col>
                    <Form.Label className="m-0">
                      <strong>Desktop Notifications</strong>
                    </Form.Label>
                    <p className="text-muted">
                      <small> Received on your desktop computer</small>
                    </p>
                    <Form.Check
                      type="checkbox"
                      label="Profile Updates"
                      name="desktopProfileUpdates"
                      onChange={this.handleChange}
                      checked={this.state.desktopProfileUpdates}
                    />

                    <Form.Check
                      type="checkbox"
                      label="New Matches"
                      name="desktopNewMatches"
                      onChange={this.handleChange}
                      checked={this.state.desktopNewMatches}
                    />
                    <Form.Check
                      type="checkbox"
                      label="Jobs (jobs from our hiring partners)"
                      name="desktopJobs"
                      onChange={this.handleChange}
                      checked={this.state.desktopJobs}
                    />
                    <Form.Check
                      type="checkbox"
                      label="News (daily rundowns, job tips, etc.)"
                      name="desktopNews"
                      onChange={this.handleChange}
                      checked={this.state.desktopNews}
                    />
                  </Col>
                </Form.Row>
                <hr />
                <Form.Row>
                  <Col>
                    <Form.Label className="m-0">
                      <strong>E-mail Notifications</strong>
                    </Form.Label>
                    <p className="text-muted">
                      <small>Received via your primary e-mail</small>
                    </p>
                    <Form.Check
                      type="checkbox"
                      label="Profile Updates"
                      name="emailProfileUpdates"
                      onChange={this.handleChange}
                      checked={this.state.emailProfileUpdates}
                    />
                    <Form.Check
                      type="checkbox"
                      label="New Matches"
                      name="emailNewMatches"
                      onChange={this.handleChange}
                      checked={this.state.emailNewMatches}
                    />
                    <Form.Check
                      type="checkbox"
                      label="Jobs (jobs from our hiring partners)"
                      name="emailJobs"
                      onChange={this.handleChange}
                      checked={this.state.emailJobs}
                    />
                    <Form.Check
                      type="checkbox"
                      label="News (daily rundowns, job tips, etc.)"
                      name="emailNews"
                      checked={this.state.emailNews}
                      onChange={this.handleChange}
                    />
                  </Col>
                </Form.Row>
                <hr />
                <Form.Row>
                  <Col>
                    <Form.Label className="mb-0">
                      <strong>Push Notifications</strong>
                    </Form.Label>
                    <p className="text-muted">
                      <small>Pop ups on your device</small>
                    </p>
                    <Form.Check
                      type="checkbox"
                      checked={this.state.pushProfileUpdates}
                      label="Profile Updates"
                      name="pushProfileUpdates"
                      onChange={this.handleChange}
                    />
                    <Form.Check
                      type="checkbox"
                      checked={this.state.pushNewMatches}
                      label="New Matches"
                      name="pushNewMatches"
                      onChange={this.handleChange}
                    />
                    <Form.Check
                      type="checkbox"
                      label="Jobs (jobs from our hiring partners)"
                      name="pushJobs"
                      checked={this.state.pushJobs}
                      onChange={this.handleChange}
                    />
                    <Form.Check
                      type="checkbox"
                      label="News (daily rundowns, job tips, etc.)"
                      name="pushNews"
                      checked={this.state.pushNews}
                      onChange={this.handleChange}
                    />
                  </Col>
                </Form.Row>
                <hr />
                <Form.Row>
                  <Col>
                    <Form.Check
                      type="checkbox"
                      label="Unsubscribe from all aha!hire Connect Communications"
                      name="unsubAll"
                      checked={this.state.unsubAll}
                      onChange={this.handleChange}
                    />
                  </Col>
                </Form.Row>
                <Form.Row className="mt-4">
                  <Col>
                    {/* <Link to="/"> */}
                    <Button
                      variant="outline-primary float-right"
                      onClick={this.handleSubmit}
                    >
                      Save
                    </Button>
                    {/* </Link> */}
                  </Col>
                </Form.Row>
              </Form>
            </SettingsContainer>
          </Col>
        </Row>
      </Container>
    );
  }
}

const SettingsContainer = styled.div`
  background: white;
  border: 3px solid #f5f5f5;
  flex: 1;
  margin-top: 20px;
  padding: 40px;
`;
const SettingsHeading = styled.div`
  border-bottom: 1px solid #f5f5f5;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
`;
const SettingsTitle = styled.p`
  font-size: 16px;
  font-weight: bold;
`;

export default NotificationSettingsScreen;
