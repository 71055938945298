import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import LoginIcon from '@material-ui/icons/Input';

import classes from './index.module.css';

function NavMenuTemplate(props) {
  const { signInLink, desktopLogo, mobileLogo } = props;
  const [navbarOpacity, changeNavbarOpacity] = useState('rgba(255,255,255,0)');
  const [navSocials, changeNavSocials] = useState('block');
  const [navSignIn, changeNavSignIn] = useState('none');
  const [navThreshold, changeNavThreshold] = useState(false);
  const pathname = window.location.pathname.replace('/', '');

  const onPageRender = () => {
    const initialPageValue = 0 + 100;
    const onScroll = () => {
      const originalScrollPosition = window.scrollY;
      const scrollPosition = window.scrollY + window.innerHeight / 8;
      // Initial Fade In
      if (initialPageValue > originalScrollPosition) {
        const scrollPercent = ((originalScrollPosition - 0) * 100) / (initialPageValue - 0);
        const navbarFadeIn = (1 * scrollPercent) / 100;
        changeNavbarOpacity(`rgba(255,255,255,${navbarFadeIn})`);
        changeNavSocials('block');
        changeNavSignIn('none');
        changeNavThreshold(false);
      }
      // Navbar Shows completely
      if (initialPageValue < originalScrollPosition) {
        changeNavbarOpacity('rgba(255,255,255,1)');
        changeNavSocials('none');
        changeNavSignIn('block');
        changeNavThreshold(true);
      }
    };
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  };

  useEffect(() => {
    onPageRender();
  }, []);

  return (
    <nav
      className={classes.navContainer}
      style={{
        background: navbarOpacity,
      }}
    >
      <Grid
        className={classes.navFullHeight}
        container
      >
        <Grid
          item
          xs={0}
          md={2}
        />
        <Grid
          className={classes.navFullHeight}
          item
          xs={4}
          md={3}
        >
          <Box
            className={clsx(classes.navLinkLogo, classes.logoDesktop)}
          >
            <Link
              to="/"
            >
              <img
                className={clsx(classes.logo, classes.desktopPadding)}
                src={desktopLogo}
                alt=""
              />
            </Link>
          </Box>
          <Box
            className={clsx(classes.navLinkLogo, classes.logoMobile)}
          >
            <Link
              to="/"
            >
              <img
                className={classes.logo}
                src={mobileLogo}
                alt=""
              />
            </Link>
          </Box>
        </Grid>
        <Grid
          item
          xs={2}
          md={2}
        />
        <Grid
          item
          xs={5}
          md={3}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <Link to="/support">
            <div
              className={classes.linkContainer}
            >
              <Typography
                className={classes.linkNeutral}
                style={{
                  color: navThreshold ? '#000' : '#fff',
                  fontSize: '0.875rem',
                  fontFamily: 'Sans-Serif',
                  marginRight: '10px',
                }}
              >
                Resources
              </Typography>
              <MenuBookIcon
                style={{
                  fill: navThreshold ? '#A2171F' : '#fff',
                }}
                className={classes.navIcon}
              />
            </div>
          </Link>
          <a
            className={classes.linkContainer}
            href={signInLink}
          >
            <Typography
              className={classes.linkNeutral}
              style={{
                color: navThreshold ? '#000' : '#fff',
                fontSize: '0.875rem',
                fontFamily: 'Sans-Serif',
                marginRight: '10px',
              }}
            >
              Sign In
            </Typography>
            <LoginIcon
              style={{
                fill: navThreshold ? '#A2171F' : '#fff',
              }}
              className={classes.navIcon}
            />
          </a>
        </Grid>
        <Grid
          item
          xs={0}
          md={2}
        />
      </Grid>
    </nav>
  );
}

NavMenuTemplate.propTypes = {};

export default NavMenuTemplate;
