import React from "react";
import styled from "styled-components";

export default {
  title: "Typography",
};

export const Paragraph = styled.p`
  font-family: Sans-Serif;
  color: #000000;
  margin: ${(props) => props.removeMargins && 0};
  color: ${(props) => props.muted && "#6b6b6b"};
  font-size: ${(props) => props.size && props.size};
`;

const StyledHOne = styled.h1`
  font-family: Sans-Serif;
  color: #000000;
`;

const StyledHTwo = styled.h2`
  font-family: Sans-Serif;
  color: #000000;
`;

const StyledHThree = styled.h3`
  font-family: Sans-Serif;
  color: #00000;
`;

// export const Paragraph = () => <StyledPargraph />;
export const HOne = () => <StyledHOne />;
export const HTwo = () => <StyledHTwo />;
export const HThree = () => <StyledHThree />;
