import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import classes from './index.module.css';

function TemplateFooter(props) {
  const {
    privacyForm,
    changePrivacyForm,
  } = props;

  const handleChangePrivacyForm = () => {
    changePrivacyForm(!privacyForm);
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  return (
    <footer
      className={classes.footerContainer}
    >
      <Grid
        container
        className={classes.footerGrid}
      >
        <Grid
          item
          xs={0}
          lg={2}
        />
        <Grid
          className={classes.footerSectionContainer}
          item
          xs={12}
          lg={3}
        />
        <Grid
          className={classes.footerSectionContainer}
          item
          xs={12}
          lg={2}
        />
        <Grid
          className={classes.footerSectionContainer}
          item
          xs={12}
          lg={3}
        >
          <Typography
            className={classes.footerGogigTypography}
          >
            Powered by
          </Typography>
          <img
            className={classes.footerGogigLogo}
            src="/icons/gogig-logo.svg"
            alt=""
          />
        </Grid>
        <Grid
          item
          xs={0}
          lg={2}
        />
      </Grid>
    </footer>
  );
}

TemplateFooter.propTypes = {};

export default TemplateFooter;
