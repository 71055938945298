import React from 'react'
import PropTypes from 'prop-types'

import data from './constants/content';
import PrivacyBodyTemplate from './components/PrivacyBodyTemplate';
import TemplateFooter from './components/TemplateFooter';

function LandingPrivacy(props) {
  const { SOTFConnect } = data;
  return (
    <>
      <PrivacyBodyTemplate
        {...SOTFConnect}
      />
      <TemplateFooter
        {...SOTFConnect}
      />
    </>
  )
}

LandingPrivacy.propTypes = {}

export default LandingPrivacy
